import React, { useContext, useState } from 'react';
import { TaskContext } from './shared/TaskContextProvider'
import {
  Button,
  TextField,
  Switch,
  Toolbar,
  Container,
  Snackbar,
  Chip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import { Link, useHistory } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './TaskDetail.css'
import ButtonWithConfirmation from './shared/ButtonWithConfirmation';
import TagList from './TagList';
import { isNumeric } from '../services/NumberService'
import { colourToStyle } from '../services/TagService';

const Alert = props => <MuiAlert elevation={6} variant="filled" {...props} />;

const TaskDetail = props => {

  let history = useHistory();

  const {
    getTaskById,
    deleteTaskById,
    addTaskToDate,
    updateTask,
    getTags
  } = useContext(TaskContext);

  const tags = getTags();
  const taskIdParam = props.match.params.taskId;
  const dateParam = props.match.params.date;

  const getStatus = () => {

    const locationState = props.location.state;
    let status = {
      message: '',
      severity: 'success'
    };

    switch (locationState?.operation) {
      case 'taskAdded':
        status.message = `The task "${locationState.taskTitle}" has been added.`;
        break;
      case 'taskDeleted':
        status.message = `The task "${locationState.taskTitle}" has been deleted.`;
        break;
      case 'invalidTaskId':
        status.message = "Invalid Task ID provided.";
        status.severity = "error";
        break;
      default: // we don't care
        break;
    }

    return status;
  };

  const redirectInvalid = () => {
    history.push({
      pathname: "/task/new",
      state: {
        operation: 'invalidTaskId'
      }
    });
  };

  // validation / new vs existing

  let isNewTaskInitial = taskIdParam === 'new' || dateParam || (!taskIdParam && !dateParam);
  let task = null;

  if (isNewTaskInitial)
    task = { id: -1, title: '', date: '', completed: false, tags: [] };
  else if (isNumeric(taskIdParam))
    task = getTaskById(+taskIdParam);

  const initialTitle = task?.title;
  const initialDate = isNewTaskInitial ? (dateParam ?? '') : task?.date;
  const initialCompleted = isNewTaskInitial ? false : task?.completed;
  const initialDescription = task?.description;
  const initialTags = task?.tags;
  const initialStatus = getStatus();

  const [isNewTask, setIsNewTask] = useState(isNewTaskInitial);
  const [isEditing, setEditing] = useState(isNewTask);
  const [taskId, setTaskId] = useState(task?.id);
  const [taskTitle, setTaskTitle] = useState(initialTitle);
  const [taskDate, setTaskDate] = useState(initialDate);
  const [taskCompleted, setTaskCompleted] = useState(initialCompleted);
  const [taskDescription, setTaskDescription] = useState(initialDescription);
  const [taskTags, setTaskTags] = useState(initialTags);
  const [statusMessage, setStatusMessage] = useState(initialStatus.message);
  const [statusSeverity] = useState(initialStatus.severity);

  if (!task && !isNewTask)
    redirectInvalid();

  const titleStyle = taskCompleted ? {textDecoration: 'line-through', fontStyle: 'italic'} : {};
  const labelCellStyle = { width: '100px' };

  const deleteThisTask = () => {
    deleteTaskById(taskDate, +taskId);
    
    history.push({
      pathname: `/task/new`,
      state: {
        taskTitle: taskTitle,
        operation: 'taskDeleted'
      }
    });
  };

  const buildTask = () => {
    return {
      title: taskTitle,
      date: taskDate,
      completed: taskCompleted,
      description: taskDescription,
      tagIds: taskTags.map(t => t.id)
    };
  };

  const addNewTask = () => {

    const newTask = buildTask();

    const newTaskId = addTaskToDate(taskDate, newTask);

    setIsNewTask(false); // once you save, it's no longer a new task
    setEditing(false);
    setTaskId(newTaskId); // set task ID in state - you can edit it right after it's saved

    // update URL

    history.push({
      pathname: `/task/${newTaskId}`,
      state: {
        taskTitle: taskTitle,
        operation: 'taskAdded'
      }
    });
  };

  const updateCurrentTask = () => {
    const newTask = {...buildTask(taskId), id: taskId};
    updateTask(initialDate, taskDate, initialTags.map(tag => tag.id), newTask);
    setEditing(false);
    setStatusMessage("Task has been updated.");
  };

  const cancelEditing = () => {
    setTaskTitle(initialTitle);
    setTaskDate(initialDate);
    setTaskCompleted(initialCompleted);
    setTaskDescription(initialDescription);
    setTaskTags(initialTags);

    setEditing(false);
  };

  const startEditing = () => setEditing(true);

  return (
  
    <Container>

      <Snackbar open={!!statusMessage} autoHideDuration={5000} onClose={() => setStatusMessage('')}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
        <Alert onClose={() => setStatusMessage('')} severity={statusSeverity}>
          {statusMessage}
        </Alert>
      </Snackbar>

      <Toolbar style={{ float: 'right'}}>

        {!isNewTask &&
          <ButtonWithConfirmation action={deleteThisTask}
                                  buttonText="Delete"
                                  dialogTitle="Are you sure you want to delete this task?"
                                  dialogContent="The task will be permanently deleted."
                                  cancelText="Cancel"
                                  confirmText="OK, delete this task"
                                  color="secondary"
                                  buttonIcon={<DeleteIcon />}
          />
        }

        {!isNewTask && (
          isEditing ?
            <ButtonWithConfirmation action={cancelEditing}
              buttonText="Cancel"
              dialogTitle="Cancel editing?"
              dialogContent="This will discard any changes you made."
              cancelText="Keep Editing"
              confirmText="Discard changes"
              color="default"
              buttonIcon={<CancelIcon />}
            />
          : <Button color="primary"
              variant="contained"
              onClick={startEditing}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
        )}

        { isEditing && <Button color="primary"
                    variant="contained"
                    disabled={!taskTitle || !taskDate}
                    onClick={isNewTask ? addNewTask : updateCurrentTask}
                    startIcon={<SaveIcon />}
          >
            {isNewTask ? 'Add' : 'Save'}
          </Button>
        }
      </Toolbar>

      <table id="taskDetailTable">
        <tbody>
          <tr>{/* TITLE */}
            <td style={labelCellStyle}>
              Title:
            </td>
            <td>
              <span style={titleStyle}>
              {isEditing ? <TextField id="newTaskName"
                                      value={taskTitle}
                                      variant="outlined"
                                      error={!taskTitle}
                                      helperText={!taskTitle && "Title is required."}
                                      fullWidth
                                      onChange={event => setTaskTitle(event.target.value)} />
                        : taskTitle}
              </span>
            </td>
          </tr>
          <tr>{/* DATE */}
            <td style={labelCellStyle}>
              Date:
            </td>
            <td>
              {isEditing ? <TextField id="date"
                                      type="date"
                                      value={taskDate}
                                      variant="outlined"
                                      error={!taskDate}
                                      helperText={!taskDate && "Date is required."}
                                      onChange={event => setTaskDate(event.target.value)} />
                          : <Link to={'/day/' + taskDate}>{taskDate}</Link>}
            </td>
          </tr>
          <tr>{/* COMPLETED */}
            <td style={labelCellStyle}>
              Completed:
            </td>
            <td>
              {
                isEditing ? <Switch checked={taskCompleted} onChange={() => setTaskCompleted(!taskCompleted)} />
                          : (taskCompleted ? <div>
                                               <DoneIcon style={{fill: "green"}} /> Yes
                                             </div>
                                           : 'No')
              }
            </td>
          </tr>
          <tr>{/* DESCRIPTION */}
            <td style={labelCellStyle}>
              Description:
            </td>
            <td>
              {
                taskDescription || isEditing
                  ? <CKEditor
                      editor={ ClassicEditor }
                      data={taskDescription}
                      disabled={!isEditing}
                      onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          setTaskDescription(data);
                      } }
                  />
                : <em>No description.</em>
              }
            </td>
          </tr>
          <tr>{/* TAGS */}
            <td style={labelCellStyle}>
              Tags:
            </td>
            <td>
              {isEditing ? <Autocomplete multiple
                                        id="tags-outlined"
                                        options={tags}
                                        getOptionLabel={tag => tag.name}
                                        filterSelectedOptions
                                        value={taskTags}
                                        onChange={(event, value, reason) => {
                                          setTaskTags(value);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Click or type to select tags..."
                                          />
                                        )}
                                        renderTags={(tagValue, getTagProps) => {
                                          return tagValue.map((option, index) => (
                                            <Chip
                                              {...getTagProps({ index })}
                                              label={option.name}
                                              style={colourToStyle(option.colour)}
                                            />
                                          ));
                                        }}
                                        renderOption={(option) => option.name}
                                        getOptionSelected = {(option, value) => {
                                          return option.id === value.id
                                        }}
                                      />
                        : (taskTags?.length > 0 ? <TagList tags={taskTags} size="medium" />
                                                : <em>No tags for this task.</em>
                          )
              }
            </td>
          </tr>
        </tbody>
      </table>
    </Container>
  );
};

export default TaskDetail;