import React from 'react';
import Alert from '@material-ui/lab/Alert';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from "react-router-dom";
import ButtonWithConfirmation from './shared/ButtonWithConfirmation';

const Home = () => {

  const now = new Date();
  const iso8601Date = now.toISOString().substring(0, 10);

  const resetButton = <ButtonWithConfirmation action={() => { window.localStorage.clear(); window.location.reload(true); }}
  buttonText="Reset"
  dialogTitle="Are you sure you want to delete your data?"
  dialogContent="This will clear all changes you made to tasks and
    tags, and revert to the sample data provided with this application."
  cancelText="Cancel"
  confirmText="Reset to sample data"
  color="primary"
  icon={<DeleteIcon />}
/>;

  return <div style={{ width: '600px', marginLeft: 'auto', marginRight: 'auto' }}>

    <Alert severity="info">
      Sirius Planner has been around since 2020. Unfortunately, there is not
      enough interest to justify keeping it around, so it will in all likelihood
      be taken down in November 2024. Enjoy it while you can in the meantime!
    </Alert>

    <h2>Welcome</h2>

    <p>Welcome to <strong>Sirius Planner 0.2.8</strong>! You can use this web application
    to organise the tasks in your day, week or month. Watch the release videos
    (<a href="https://www.youtube.com/watch?v=H3bxH7ZGQvA">0.2 - latest</a> /&nbsp;
    <a href="https://www.youtube.com/watch?v=hhHfzzwn-x4">0.1 - previous</a>)
    to learn the ropes, or keep reading below.</p>

    <Alert severity="warning">
      <p style={{ marginTop: 0 }}>There is currently no central database storing your tasks. Any changes
      you make will be saved only in your browser, and they will not carry
      across to different browsers or devices.</p>
      
      <p style={{ marginBottom: 0 }}>Click&nbsp;
        {resetButton}
      &nbsp;if you ever want to discard your changes and start afresh.</p>
    </Alert>

    <h2>Finding Your Way Around</h2>

    <p><Link to={"/week/" + iso8601Date}>This Week</Link> shows you a list
    of tasks per day for the current week only. Here, you can:</p>

    <ul>
      <li>Reorder tasks in a day to prioritise them (drag the handle at the top-left corner)</li>
      <li>Move tasks to different days to plan your workload (drag the handle at the top-left corner)</li>
      <li>Toggle tasks' <em>completed</em> status (click the button next to the task's title)</li>
      <li>Add a new task on that particular day (click on the <AddCircleIcon fontSize="small" /> button)</li>
      <li>Click on a particular date to see that particular day in more detail</li>
      <li>Click on the title of a task to view it in an expanded view and perform more actions</li>
      <li>Move the view to an earlier or later week</li>
    </ul>

    <p>The <Link to={"/month/" + iso8601Date}>5-week view</Link> is an expanded
    version of the weekly view that shows you the current week as well as two weeks
    before and after it. Anything you can do in the weekly view can also be done in
    the 5-week view; the latter just shows you more information. The 5-week view is
    similar to your typical calendar's monthly view, except that it is not focused
    on any particular month.</p>

    <p>You can see the tasks for a particular day either by clicking on&nbsp;
    <Link to={"/day/" + iso8601Date}>Today</Link> in the navbar, or by clicking on
    any date in either the weekly or 5-week views. This view shows the tasks for the
    day more comfortably, and also displays the task's description if it has one.</p>

    <p>When you click on the title of a particular task from any of the above views,
    you will see a detailed view of the task, and you are able to modify or delete the
    task.</p>

    <p>You can add a new task either using the <em>Add New Task</em> button in the
    top-right corner, or using the <AddCircleIcon fontSize="small" /> button on
    any particular day. The latter will set the <em>Date</em> field for you.</p>

    <h2>Tags</h2>

    <p>Each task can have tags that give it more context. These show up in any view
    where the task appears, and are useful to organise tasks. Each new tag can be
    given a distinct colour that helps tasks with that tag stand out. Clicking on a tag
    will show you a list of tasks associated with that tag, which you can reorder
    within the context of that tag. This is really useful when prioritising tasks
    in a project or assigned to an individual.</p>

    <p>Use the <Link to={"/tags/"}>Manage Tags</Link> link to view/update/delete
    existing tags and add new ones. Tags are arbitrary and can represent categories,
    projects, people and more. You can even use them to assign the same task to multiple
    people if you want.</p>

    <h2>Support Sirius Planner</h2>

    <p>There are two main ways in which you can support this project:</p>
    <ol>
      <li>Your <strong>feedback</strong> helps shape this project and keep
        it going in the right direction. I periodically send surveys to see how
        things are going, and you can also reach me directly at&nbsp;
        <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#100;&#97;&#110;&#100;&#97;&#103;&#111;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;">
        &#100;&#97;&#110;&#100;&#97;&#103;&#111;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;</a>.
      </li>
      <li><strong>Spread the word</strong>. The more people know about
        Sirius Planner, the better its chances of developing into a
        mature product. </li>
      {/* <li>While this is mainly a passion project and I won't be making a living
        from it, I will need <strong>financial</strong> support to develop it to
        the point where servers are required. If you'd like to make a small
        donation or are willing to pay a small subscription fee to use the
        product, please let me know.
      </li> */}
    </ol>
    
  </div>;
}

export default Home;