const calendarWeekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const javaScriptWeekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const getDayOfWeek = (dayNo, weekDays) => {
  if (dayNo < 0 || dayNo >= 7)
    return null;
  return weekDays[dayNo];
};

const getCalendarDayOfWeek = (dayNo) => getDayOfWeek(dayNo, calendarWeekDays);
export const getJavaScriptDayOfWeek = (dayNo) => getDayOfWeek(dayNo, javaScriptWeekDays);


export const getCalendarDayOfWeekShort = (dayNo) => getCalendarDayOfWeek(dayNo)?.substring(0, 3);

export const toIso8601Str = date => date.toISOString().substring(0, 10);

// date functions
// ref: https://stackoverflow.com/questions/4413590/javascript-get-array-of-dates-between-2-dates
// ref: https://stackoverflow.com/questions/4156434/javascript-get-the-first-day-of-the-week-from-current-date

export const addDays = (date, days) => {
  let newDate = new Date(date.valueOf());
  newDate.setDate(date.getDate() + days);
  return newDate;
}

export const getDaysArray = (start, end) => {
  for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
      arr.push(new Date(dt));
  }
  return arr;
};

export const getMonday = d => {
  d = new Date(d);
  var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

// ref: https://stackoverflow.com/questions/8495687/split-array-into-chunks

// var i,j,temparray,chunk = 10;
// for (i=0,j=array.length; i<j; i+=chunk) {
//     temparray = array.slice(i,i+chunk);
//     // do whatever
// }

export const getWeeks = (now, weekRange) => {
  const daysInWeek = 7;
  const lastMonday = getMonday(now);
  // include weekRange weeks ago, current week, and weekRange weeks after this week
  const days = getDaysArray(
    addDays(lastMonday, -daysInWeek * weekRange),
    addDays(lastMonday, daysInWeek * weekRange + 6)
  );

  let weeks = [];
  for (let i = 0; i < days.length; i += daysInWeek)
    weeks.push(days.slice(i, i + daysInWeek));

  return weeks;
};