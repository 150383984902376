// private

const startsWithVowel = str => {
  switch(str[0]) {
    case 'a':
    case 'e':
    case 'i':
    case 'o':
    case 'u':
      return true;
    default:
      return false;
  }
};

// public

export const colourToStyle = colour => ({
  color: colour.foreground,
  backgroundColor: colour.background
});

export const colourToStr = colour =>
  `${colour.foreground} text on a${startsWithVowel(colour.background) ? 'n' : ''} ${colour.background} background`;