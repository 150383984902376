import React, { useContext, useState, useEffect } from 'react';
import { Button, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Link } from "react-router-dom";
import { TaskContext } from './shared/TaskContextProvider';
import { colourToStyle } from '../services/TagService';
import './TagManager.css';

const TagManager = props => {

  const { getTags } = useContext(TaskContext);

  const [statusMessage, setStatusMessage] = useState('');
  const [statusSeverity, setStatusSeverity] = useState('');

  useEffect(() => {
    const locationState = props.location.state;

    switch (locationState?.operation) {
      case 'deleted':
        setStatusSeverity('success');
        setStatusMessage(`The '${locationState.tagName}' tag was deleted.`);
        break;
      case 'invalidTagId':
        setStatusSeverity('error');
        setStatusMessage('Invalid Tag ID provided.');
        break;
      default: // we don't care
        break;
    }
  }, [props.location.state]);

  const tags = getTags();

  return (
    <div className="narrow">

      <Snackbar open={!!statusMessage} autoHideDuration={5000} onClose={() => setStatusMessage('')}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}>
        <MuiAlert elevation={6} variant="filled" onClose={() => setStatusMessage('')} severity={statusSeverity}>
          {statusMessage}
        </MuiAlert>
      </Snackbar>

      <h2>Manage Tags</h2>

      <Link to="/tag/new" style={{ float: 'right', textDecoration: 'none' }}>
        <Button color="primary"
            variant="contained"
            startIcon={<AddCircleIcon />}
        >
          Add New Tag
        </Button>
      </Link>

      <table id="existingTagsTable">
        <tbody>
          {
            Object.values(tags).map(tag => (
              <tr key={tag.id} style={{...colourToStyle(tag.colour), margin: '5px'}}>
                <td>
                  {tag.name}
                </td>
                <td style={{ float: 'right' }}>
                  <Link to={"/tag/" + tag.id} style={{ textDecoration: 'none' }}>
                    <Button variant="contained">
                      View/Edit
                    </Button>
                  </Link>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

export default TagManager;