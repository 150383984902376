import React from 'react';
import { AppBar, Toolbar, Button } from '@material-ui/core';
import { BrowserRouter, Route, Link, Switch } from "react-router-dom";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HomeIcon from '@material-ui/icons/Home';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import TodayIcon from '@material-ui/icons/Today';
import './App.css';
import Home from './components/Home'
import Day from './components/Day'
import Calendar from './components/Calendar'
import TaskDetail from './components/TaskDetail'
import TaskContextProvider from './components/shared/TaskContextProvider'
import TagManager from './components/TagManager'
import Tag from './components/Tag'
import NotFound from './components/NotFound'
import NavDateButton from './components/NavDateButton'

function App() {

  return (
    <div className="App">
      <header className="App-header">
        
        <TaskContextProvider>
          <BrowserRouter>
            <AppBar id="navBar">
              <Toolbar id="toolBar1">
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td>
                        <Link to="/">
                          <Button variant="contained" startIcon={<HomeIcon />}>
                            Home
                          </Button>
                        </Link>
                        <NavDateButton prefix="/day/" icon={<TodayIcon />} label="Today" />
                        &nbsp;&nbsp;
                        <NavDateButton prefix="/week/" icon={<DateRangeIcon />} label="This Week" />
                        &nbsp;&nbsp;
                        <NavDateButton prefix="/month/" icon={<CalendarViewDayIcon />} label="5-Week View" />
                        <Link to={"/tags/"}>
                          <Button variant="contained" startIcon={<LocalOfferIcon />}>
                            Manage Tags
                          </Button>
                        </Link>
                      </td>
                      <td>
                        <Link to={"/task/new"}>
                          <Button variant="contained"
                                  style={{ float: 'right' }}
                                  startIcon={<AddCircleIcon />}
                          >
                            Add New Task
                          </Button>
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Toolbar>
            </AppBar>
            <Toolbar id="toolBar2" />{/* Hack to make AppBar not overlap content - ref https://stackoverflow.com/a/63300755/983064 */}

            <h1>Sirius Planner</h1>

            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/day/:date" component={Day} />
              <Route path="/week/:date" key="week-view" render={props => <Calendar {...props} weekRange={0} />} />
              <Route path="/month/:date" key="month-view" render={props => <Calendar {...props} weekRange={2} />} />
              <Route path="/task/new/:date?" key="new-task" component={TaskDetail} />
              <Route path="/task/:taskId" key="view-task" component={TaskDetail} />
              <Route path="/tags" exact component={TagManager} />
              <Route path="/tag/:tagId" key="view-tag" exact component={Tag} />
              <Route component={NotFound} />
            </Switch>
          </BrowserRouter>
        </TaskContextProvider>
        
        <div id="bottom">
          v. 0.2.8 &copy; <a href="https://dandago.com/">Daniel D'Agostino</a> 2020-{new Date().getFullYear()}
        </div>

      </header>
    </div>
  );
}

export default App;
