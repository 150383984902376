import React from 'react';
import TaskList from './TaskList'
import { getJavaScriptDayOfWeek } from '../services/DateService'

const Day = props => {

  let date = props.match.params.date;

  const dayNo = new Date(Date.parse(date)).getUTCDay();

  return (
    <div style={{ width: '800px', marginLeft: 'auto', marginRight: 'auto' }}>
      <TaskList date={date} dayOfWeek={getJavaScriptDayOfWeek(dayNo)} compact={false} />
    </div>
  );
};

export default Day;