import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import { Link } from "react-router-dom";
import { Draggable } from 'react-smooth-dnd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './Task.css';
import TagList from './TagList';

const StyledListItem = withStyles({
  root: {
    padding: 0
  }
})(ListItem);

const Task = props => {

  let task = props.task;

  let descStyle = task.completed ? {textDecoration: 'line-through', fontStyle: 'italic'} : {};
  if (props.showDescription)
    descStyle.fontWeight = 'bold';
  const showDate = props.showDate ?? false;

  const completeTaskButtonSize = '22px';
  const completeTaskButtonStyle = {
    float: 'right',
    padding: 0,
    maxWidth: completeTaskButtonSize,
    maxHeight: completeTaskButtonSize,
    minWidth: completeTaskButtonSize,
    minHeight: completeTaskButtonSize
  }

  return (
    <Draggable>
      <StyledListItem className="task">
        <table>
          <tbody>
            <tr>
              <td className="dragHandleCell">
                <span className="column-drag-handle" style={{ paddingLeft:'5px', cursor: 'grab' }}>
                  &#x2630;
                </span>
              </td>
              <td>
                <span className="taskTitle">
                  <span style={descStyle}>
                    <Link to={"/task/" + task.id}>
                      {task.title}
                    </Link>
                  </span>
                  {showDate && <> (<Link to={"/day/" + task.date}>{task.date}</Link>)</>}
                </span>
              </td>
              <td>
                <Tooltip title={(task.completed ? "Unc" : "C") + "omplete this task"}>
                  <Button color="default"
                          variant="contained"
                          size="small"
                          style={completeTaskButtonStyle}
                          onClick={() => props.toggleTaskComplete(task.id)}
                  >
                    {task.completed ? <ClearIcon />
                                    : <CheckCircleIcon fontSize="small" />}
                  </Button>
                </Tooltip>
              </td>
            </tr>
            {
              props.showDescription &&
                <tr>
                  <td colSpan="3" style={{padding: '5px' }}>
                    {
                      task.description
                        ? <CKEditor
                            editor={ ClassicEditor }
                            data={task.description}
                            disabled={true}
                            config={{ toolbar: [], isReadOnly: true,}}
                          />
                        : <em>No description.</em>
                      }
                  </td>
                </tr>
            }
            <tr>
              <td colSpan="3" style={{paddingLeft: '5px', paddingRight: '5px' }}>
                <TagList tags={task.tags} size="small" />
              </td>
            </tr>
          </tbody>
        </table>
      </StyledListItem>
    </Draggable>
  );
}

export default Task;