import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ButtonWithConfirmation = props => {

  const action = props.action; // this is what happens when the user confirms
  const buttonText = props.buttonText; // text displayed in the button
  const buttonIcon = props.buttonIcon; // icon displayed in the button
  const dialogTitle = props.dialogTitle; // text displayed in the dialog title
  const dialogContent = props.dialogContent; // text displayed in the dialog content
  const cancelText = props.cancelText; // text displayed text for the Cancel option
  const confirmText = props.confirmText; // text displayed text for the Confirm option
  const color = props.color; // button colour (primary / secondary)
  const disabled = props.disabled; // disabled condition to go on button
  const float = props.float; // optional, set button float style (e.g. float: right)

  const [dialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const performActionAndCloseDialog = () => {
    action();
    closeDialog();
  }

  return (
    <>
      <Button color={color}
              variant="contained"
              onClick={openDialog}
              startIcon={buttonIcon}
              disabled={disabled}
              style={float ? { float: float } : {}}
      >
        {buttonText}              
      </Button>
      <Dialog open={dialogOpen}
              onClose={closeDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" autoFocus>
            {cancelText}
          </Button>
          <Button onClick={performActionAndCloseDialog} color="secondary">
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ButtonWithConfirmation;