import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TaskList from './TaskList'
import { addDays, getWeeks, getCalendarDayOfWeekShort, toIso8601Str } from '../services/DateService'
import './Calendar.css'

const Calendar = props => {

  const weekRange = props.weekRange; // display this week +/- this number of weeks
  const now = new Date();

  const [pivotDate, setPivotDate] = useState(now);

  let history = useHistory();

  let weeks = getWeeks(pivotDate, weekRange);

  const moveWeek = direction => {
    const newPivotDate = addDays(pivotDate, direction * 7);
    setPivotDate(newPivotDate);
    
    // update URL
    const weekOrMonth = weekRange === 0 ? 'week' : 'month';
    const newPivotDateStr = toIso8601Str(newPivotDate);
    history.push(`/${weekOrMonth}/${newPivotDateStr}`);
  }
  const move1WeekEarlier = () => moveWeek(-1);
  const move1WeekLater = () => moveWeek(1);
  
  return (
    <div className="calendar">
      <Button color="primary"
              variant="contained"
              onClick={move1WeekEarlier}
              startIcon={<ArrowUpwardIcon />}
      >
          Shift 1 Week Earlier
      </Button>
      <table>
        <tbody>
          {
            weeks.map(week => {
              return <tr key={"week-starting-" + week[0]}>
                {
                  week.map((day, dayNo) => {
                    let date = toIso8601Str(day);
                    let dayClass = "day" + (date === toIso8601Str(now) ? " today" : "");
                    return <td key={day} className={dayClass}>
                      <TaskList date={date}
                                dayOfWeek={getCalendarDayOfWeekShort(dayNo)}
                                compact={true} />
                    </td>
                  })
                }
              </tr>
            })
          }
        </tbody>
      </table>
      <Button color="primary"
              variant="contained"
              onClick={move1WeekLater}
              startIcon={<ArrowDownwardIcon />}
      >
          Shift 1 Week Later
      </Button>
    </div>
  );
};

export default Calendar;