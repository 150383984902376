import React, { useState, createContext } from 'react';
import { sampleTags, sampleTagColours, sampleTasks } from '../../services/SampleDataService';

// ref: https://levelup.gitconnected.com/state-management-in-react-using-context-api-and-hooks-931c9842f204
// ref: https://blog.logrocket.com/use-hooks-and-context-not-react-and-redux/#:~:text=The%20React%20Context%20API,-The%20new%20Context&text=Context%20provides%20a%20way%20to,that%20are%20not%20directly%20connected.
// ref: https://reactjs.org/docs/context.html

export const TaskContext = createContext();

const TaskContextProvider = props => {

  const readJsonFromLocalStorage = key => {
    const data = window.localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  const buildInitialTasks = () => {
    // transform tasks from a simple list to an object where date maps to array of task 
    let tasksByDate = {};

    for (const task of sampleTasks) {
      if (!tasksByDate[task.date])
      tasksByDate[task.date] = [];

      tasksByDate[task.date].push(task);
    }
    
    return tasksByDate;
  };

  const computeInitialNextTaskId = () => Math.max(...sampleTasks.map(task => task.id)) + 1;
  const computeInitialNextTagId = () => Math.max(...sampleTags.map(tag => tag.id)) + 1;

  const initialTasks = readJsonFromLocalStorage('tasks') ?? buildInitialTasks();
  const initialNextTaskId = readJsonFromLocalStorage('nextTaskId') ?? computeInitialNextTaskId();
  const initialTags = readJsonFromLocalStorage('tags') ?? sampleTags;
  const initialNextTagId = readJsonFromLocalStorage('nextTagId') ?? computeInitialNextTagId();
  
  const [tasks, setTasks] = useState(initialTasks);
  const [nextTaskId, setNextTaskId] = useState(initialNextTaskId);
  const [tags, setTags] = useState(initialTags);
  const [nextTagId, setNextTagId] = useState(initialNextTagId);
  const [tagColours] = useState(sampleTagColours);

  // private functions

  const saveTasks = newTasks => {
    setTasks(newTasks);
    window.localStorage.setItem('tasks', JSON.stringify(newTasks));
  };

  const saveNextTaskId = newNextTaskId => {
    setNextTaskId(newNextTaskId);
    window.localStorage.setItem('nextTaskId', newNextTaskId);
  };

  const saveTags = newTags => {
    setTags(newTags);
    window.localStorage.setItem('tags', JSON.stringify(newTags));
  };

  const saveNextTagId = newNextTagId => {
    setNextTagId(newNextTagId);
    window.localStorage.setItem('nextTagId', newNextTagId);
  };

  const getTagByIdPrivate = tagId => tags.find(tag => tag.id === tagId);

  const replaceTaskById = (dateTasks, newTask) =>
    dateTasks?.map(oldTask => oldTask.id === newTask.id ? newTask : oldTask) ?? [newTask];

  const removeTaskById = (dateTasks, newTask) =>
    dateTasks?.filter(oldTask => oldTask.id !== newTask.id) ?? [];
  
  const buildTaskWithTags = task => ({
    ...task,
    tags: task?.tagIds.map(tagId => buildTagWithColour(getTagByIdPrivate(tagId)))
  });

  const buildTagWithColour = tag => tag ? ({
    ...tag,
    colour: tagColours.find(c => c.id === tag.colourId)
  }) : tag;

  const buildTagWithTasksAndColour = tag => ({
    ...tag,
    tasks: tag?.taskIds?.map(taskId => getTaskById(taskId)),
    colour: tagColours.find(c => c.id === tag.colourId)
  });

  const addTaskToTag = (tags, tagId, taskId) => {
    const oldTag = getTagByIdPrivate(tagId);
    const oldTaskIds = oldTag.taskIds;
    const newTaskIds = oldTaskIds ? [...oldTaskIds, taskId] : [taskId];
    const newTag = {...oldTag, taskIds: newTaskIds };
    const newTags = tags.map(tag => tag.id === tagId ? newTag : tag);
    return newTags;
  };

  const removeTaskFromTag = (tags, tagId, taskId) => {
    const oldTag = getTagByIdPrivate(tagId);
    const oldTaskIds = oldTag.taskIds;
    const newTaskIds = oldTaskIds ? oldTaskIds.filter(tId => tId !== taskId) : [];
    const newTag = {...oldTag, taskIds: newTaskIds };
    const newTags = tags.map(tag => tag.id === tagId ? newTag : tag);
    return newTags;
  }

  // public functions

  const getTasksForDate = date => tasks[date]?.map(task => buildTaskWithTags(task));

  const setTasksForDate = (date, value) => {
    let newTasks = {...tasks};
    newTasks[date] = value;
    saveTasks(newTasks);
  };

  const addTaskToDate = (date, task) => {
    
    let newTasks = {...tasks};

    if (!newTasks[date])
      newTasks[date] = [];

    const newTaskId = nextTaskId;

    newTasks[date].push({...task, id: newTaskId});
    saveTasks(newTasks);

    saveNextTaskId(newTaskId + 1);

    let updatedTags = tags;
    if (task.tagIds) {
      for (const tagId of task.tagIds) {
        updatedTags = addTaskToTag(updatedTags, tagId, newTaskId);
      }
    }
    saveTags(updatedTags);

    return newTaskId;
  };

  const updateTask = (oldDate, newDate, oldTagIds, newTask) => {
    let newTasks = {...tasks};

    // remove from old date
    if (oldDate !== newDate)
      newTasks[oldDate] = removeTaskById(tasks[oldDate], newTask);

    // add/update to new date
    newTasks[newDate] = replaceTaskById(tasks[newDate], newTask);

    // update tasks in tags

    const newTaskTagIds = newTask.tagIds;
    const addedTagIds = newTaskTagIds.filter(tagId => !oldTagIds.includes(tagId));
    const removedTagIds = oldTagIds.filter(tagId => !newTaskTagIds.includes(tagId));
    let updatedTags = tags;

    for (const tagId of addedTagIds)
      updatedTags = addTaskToTag(updatedTags, tagId, newTask.id);

    for (const tagId of removedTagIds)
      updatedTags = removeTaskFromTag(updatedTags, tagId, newTask.id);
    
    saveTags(updatedTags);
    saveTasks(newTasks);
  }

  const getTaskById = id => {
    // obviously inefficient
    let tasksByDate = Object.values(tasks);
    for (let i = 0; i < tasksByDate.length; i++) {
      const dateTasks = tasksByDate[i];
      const task = dateTasks.find(x => x.id === +id);
      if (task)
        return buildTaskWithTags(task);
    }

    return null;
  };

  const deleteTaskById = (date, id) => {
    
    // remove task from corresponding tags

    const taskTagIds = tasks[date].find(x => x.id === id).tagIds;

    let updatedTags = tags;

    for (const tagId of taskTagIds)
      updatedTags = removeTaskFromTag(updatedTags, tagId, id);
    
    saveTags(updatedTags);

    // remove task

    saveTasks({...tasks, [date]: tasks[date].filter(x => x.id !== id)});
  }

  const toggleTaskComplete = (dateStr, id) => {
    const date = tasks[dateStr];
    const newDate = date.map(task => task.id === id ? {...task, completed: !task.completed} : task);
    const newTasks = {...tasks, [dateStr]: newDate};
    saveTasks(newTasks);
  };

  const getTags = () => tags.map(tag => buildTagWithTasksAndColour(tag));

  const addTag = (tag) => {
    const newTagId = nextTagId;
    saveTags([...tags, {...tag, id: nextTagId}]);
    saveNextTagId(nextTagId + 1);
    return newTagId;
  };

  const updateTag = updatedTag => {
    const newTags = tags.map(t => t.id === updatedTag.id ? updatedTag : t);
    saveTags(newTags);
  };

  const deleteTag = tagId => {
    const newTags = tags.filter(t => t.id !== tagId);
    saveTags(newTags);
  };

  const getTagById = tagId => {
    const tag = getTagByIdPrivate(tagId);
    if (tag)
      return buildTagWithTasksAndColour(tag);
    else
      return null;
  }

  // export

  const providerObject = {
    getTasksForDate,
    setTasksForDate,
    getTaskById,
    deleteTaskById,
    addTaskToDate,
    updateTask,
    toggleTaskComplete,
    getTags,
    addTag,
    updateTag,
    deleteTag,
    tagColours,
    getTagById
  };

  return (
    <TaskContext.Provider value={providerObject}>
      {props.children}
    </TaskContext.Provider>
  );
};

export default TaskContextProvider;