import React from 'react';
import Chip from '@material-ui/core/Chip';
import { Link } from "react-router-dom";
import { colourToStyle } from '../services/TagService'

const TagList = props => {

  const tags = props.tags;
  const size = props.size ?? 'medium';

  return tags?.map(tag => <Link to={"/tag/" + tag.id} key={tag.name}>
    <Chip
      label={tag.name}
      size={size}
      style={{...colourToStyle(tag.colour), margin: '3px', cursor: 'pointer', textDecoration: 'none'}}
    />
  </Link>);
};

export default TagList;