export const sampleTagColours = [{
  id: 0,
  background: 'red',
  foreground: 'white'
}, {
  id: 1,
  background: 'navy',
  foreground: 'white'
}, {
  id: 2,
  background: 'yellow',
  foreground: 'black'
}, {
  id: 3,
  background: 'black',
  foreground: 'white'
}, {
  id: 4,
  background: 'aqua',
  foreground: 'black'
}, {
  id: 5,
  background: 'lime',
  foreground: 'black'
}, {
  id: 6,
  background: 'fuchsia',
  foreground: 'black'
}];

export const sampleTags = [{
  id: 0,
  name: 'Shopping',
  colourId: 0,
  taskIds: [1, 2]
}, {
  id: 1,
  name: 'Fun',
  colourId: 1,
  taskIds: [0, 3]
}, {
  id: 2,
  name: 'Work',
  colourId: 2,
  taskIds: [2, 4]
}, {
  id: 3,
  name: 'Sirius Planner',
  colourId: 3,
  taskIds: [5, 6, 7, 8]
}, {
  id: 4,
  name: 'COVID19',
  colourId: 6,
  taskIds: [9, 10, 11]
}];

export const sampleTasks = [{
  id: 0,
  title: 'Meet Friends',
  date: '2020-11-17',
  tagIds: [1]
}, {
  id: 1,
  title: 'Buy milk',
  date: '2020-11-17',
  completed: true,
  tagIds: [0]
}, {
  id: 2,
  title: 'Buy new suit',
  date: '2020-11-18',
  tagIds: [0, 2]
}, {
  id: 4,
  title: 'Read report',
  description: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas facilisis justo urna, vitae vulputate justo dictum nec. Donec lacus felis, lacinia vel fringilla vitae, scelerisque sagittis nibh. Ut efficitur suscipit libero non eleifend. Proin ultricies lectus in tellus congue, ac sagittis eros scelerisque. Nunc elementum eleifend eros et dictum. Sed pharetra metus ut dui ultricies tincidunt. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sem mi, mattis quis malesuada eu, pretium a libero. Donec interdum velit sed pretium dapibus. Aliquam erat volutpat. Mauris viverra gravida odio a semper. Quisque hendrerit ligula nec turpis faucibus sagittis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aenean mattis ut felis ac sagittis. Ut vel semper lacus.</p>
                <p>Quisque cursus bibendum egestas. Donec sagittis lacus leo, eu rhoncus mi tincidunt vitae. Mauris consectetur eros turpis, a fringilla mi mattis vel. Nam sed diam sit amet elit suscipit hendrerit. Sed porttitor, sapien sed venenatis dictum, nulla felis fermentum nibh, a mollis diam purus eu neque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempor at turpis eget fermentum.</p>`,
  date: '2020-11-19',
  tagIds: [2]
}, {
  id: 3,
  title: 'Go to cinema',
  description: 'Watch a film or something.',
  date: '2020-11-20',
  tagIds: [1]
}, {
  id: 5,
  title: 'Final testing',
  completed: true,
  date: '2020-12-12',
  tagIds: [3]
}, {
  id: 6,
  title: 'Create video',
  description: `<p>Make a video to explain what Sirius Planner is and what's new in 0.2.</p>
                <p>It should be short and sweet.</p>`,
  date: '2020-12-13',
  tagIds: [3]
}, {
  id: 7,
  title: 'Release 0.2',
  description: '<p>Upload the 0.2 version to the server.</p>',
  date: '2020-12-13',
  completed: true,
  tagIds: [3]
}, {
  id: 8,
  title: 'Tag release',
  desrciption: '<p>Tag the commit on which the release is based.</p>',
  date: '2020-12-13',
  tagIds: [3]
}, {
  id: 9,
  title: 'Wash hands',
  date: '2020-12-12',
  tagIds: [4]
}, {
  id: 10,
  title: 'Wear a mask',
  date: '2020-12-13',
  tagIds: [4]
}, {
  id: 11,
  title: 'Keep distance',
  date: '2020-12-11',
  tagIds: [4]
}];