import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";

const NavDateButton = props => {
    
  const history = useHistory();
  const prefix = props.prefix;
  const icon = props.icon;
  const label = props.label

  const getIso8601Date = () => {
    const now = new Date();
    const iso8601Date = now.toISOString().substring(0, 10);
    return iso8601Date;
  }

  const goToUri = () => history.push(prefix + getIso8601Date());

  return (
    <Button variant="contained" startIcon={icon} onClick={goToUri}>
      {label}
    </Button>
  );
};

export default NavDateButton;