import React from 'react';

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <img src="/sidious.jpg" alt="Sidious Planner" />
      <p>Tired of trying Sirius Planner? Why not try the dark side instead?</p>
    </div>
  )
};

export default NotFound;