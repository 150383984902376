import React, { useContext } from 'react';
import { List, Button, Tooltip } from '@material-ui/core';
import { Container } from 'react-smooth-dnd';
import Task from './Task'
import './TaskList.css';
import { TaskContext } from './shared/TaskContextProvider'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Link, useHistory } from "react-router-dom";

// https://github.com/kutlugsahin/react-smooth-dnd

const TaskList = props => {

  const { getTasksForDate, setTasksForDate, toggleTaskComplete } = useContext(TaskContext);

  let history = useHistory();

  const dayOfWeek = props.dayOfWeek;
  const compact = props.compact; // true if calendar, false if day view
  const tasksForCurrentDate = getTasksForDate(props.date) ?? [];

  const compactButtonSize = '22px';
  const compactButtonStyle = {
    padding: 0,
    maxWidth: compactButtonSize,
    maxHeight: compactButtonSize,
    minWidth: compactButtonSize,
    minHeight: compactButtonSize
  }

  const applyDrag = (dragResult, date) => {

    //console.log(date, dragResult);
    //console.log("applyDrag", date, dragResult.removedIndex, dragResult.addedIndex, dragResult.payload);

    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null)
      return null;
  
    const result = [...tasksForCurrentDate];
    let taskToAdd = payload;
  
    if (removedIndex !== null) {
      taskToAdd = result.splice(removedIndex, 1)[0];
    }
  
    if (addedIndex !== null) {
      result.splice(addedIndex, 0, {...taskToAdd, date: date});
    }
  
    //console.log(date, result);

    return result;
  };

  const setTasksForCurrentDate = value => setTasksForDate(props.date, value);

  const goToNew = () => {
    history.push('/task/new/' + props.date);
  };

  return (
    <div className="taskList">
      <table className="taskListHeader">
        <tbody>
          <tr>
            <td style={{ fontWeight: 'bold' }}>
              {
                compact ? <span>
                            {dayOfWeek}
                            &nbsp;
                            <Link to={"/day/" + props.date}>
                              {props.date}
                            </Link>
                          </span>
                        : <h2 style={{ textAlign: 'left' }}>{dayOfWeek} {props.date}</h2>
              }

            </td>
            <td style={{ textAlign: 'right'}}>
              <Tooltip title={"Add task on " + props.date}>
                <Button color="primary"
                        variant="contained"
                        size="small"
                        style={compact ? compactButtonStyle : {}}
                        onClick={goToNew}
                        startIcon={compact ? null : <AddCircleIcon />}
                >
                  {
                    compact ? <AddCircleIcon fontSize="small" /> : 'Add'
                  }
                </Button>
              </Tooltip>
            </td>
          </tr>
        </tbody>
      </table>

      {
        (compact || tasksForCurrentDate?.length > 0)
          ? <List>
              <Container getChildPayload={i => tasksForCurrentDate[i]}
                        groupName="taskList"
                        dragHandleSelector=".column-drag-handle"
                        onDrop={e => {
                          const dragResult = applyDrag(e, props.date);
                          if (dragResult)
                            setTasksForCurrentDate(dragResult);
                        }}
                        dragClass="taskDrag"
                        dropPlaceholder={{                      
                          animationDuration: 150,
                          showOnTop: true,
                          className: 'taskDropPreview' 
                        }}
              >
                {tasksForCurrentDate.map(task =>
                        <Task key={task.id}
                              task={task}
                              toggleTaskComplete={() => toggleTaskComplete(task.date, task.id)}
                              showDescription={!compact} />
                )}
              </Container>
            </List>
          : <em>There are no tasks on this particular day.</em>
      }
    </div>
  );
};

export default TaskList;
