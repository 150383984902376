import React, { useContext, useState, useEffect } from 'react';
import { List, Button, Toolbar, TextField, Select, MenuItem, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { useHistory } from "react-router-dom";
import { Container } from 'react-smooth-dnd';
import { TaskContext } from './shared/TaskContextProvider';
import { colourToStyle, colourToStr } from '../services/TagService';
import { isNumeric } from '../services/NumberService'
import ButtonWithConfirmation from './shared/ButtonWithConfirmation';
import Task from './Task'

const Tag = props => {

  const history = useHistory();

  const {
    getTagById,
    toggleTaskComplete,
    addTag,
    getTags,
    tagColours,
    updateTag,
    deleteTag
  } = useContext(TaskContext);

  const tagIdParam = props.match.params.tagId;
  const initialIsNewTag = tagIdParam === 'new';

  const redirectInvalid = () => {
    history.push({
      pathname: "/tags",
      state: {
        operation: 'invalidTagId'
      }
    });
  };

  const tag = isNumeric(tagIdParam) ? getTagById(+tagIdParam) : undefined;
  if (!initialIsNewTag && !tag)
    redirectInvalid();

  const tags = getTags();
  const existingTagNames = tags.map(tag => tag.name.toLowerCase());
  const tagNameExists = str => existingTagNames.includes(str.toLowerCase());

  const initialTagTasks = tag?.tasks;
  const initialTagName = initialIsNewTag ? '' : tag?.name;
  const initialTagColourId = initialIsNewTag ? '' : tag?.colourId;

  const [tagId, setTagId] = useState(tag?.id);
  const [tagName, setTagName] = useState(initialTagName);
  const [tagColourId, setTagColourId] = useState(initialTagColourId);
  const [tagTasks, setTagTasks] = useState(initialTagTasks);
  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    const tagIdParam = props.match.params.tagId;
    const initialIsNewTag = tagIdParam === 'new';
  
    const tag = isNumeric(tagIdParam) ? getTagById(+tagIdParam) : undefined;
    if (!initialIsNewTag && !tag)
      redirectInvalid();

    setTagId(tag?.id);
    setTagName(initialIsNewTag ? '' : tag?.name);
    setTagColourId(initialIsNewTag ? '' : tag?.colourId);
    setTagTasks(tag?.tasks);

  }, [props.match.params.tagId, getTagById])
  
  const applyDrag = dragResult => {

    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null)
      return null;

    let newTagTasks = [...tagTasks];
  
    let taskToAdd = payload;
    if (removedIndex !== null)
      taskToAdd = newTagTasks.splice(removedIndex, 1)[0];
  
    if (addedIndex !== null)
      newTagTasks.splice(addedIndex, 0, taskToAdd);

    return newTagTasks;
  };

  const isNewTag = () => tagId !== 0 && !tagId;

  const makeColourDropDownStyle = colourId => {

    const fullWidthStyle = { width: '100%' };

    if (colourId !== '') {
      const colour = tagColours.find(c => c.id === colourId);
      if (colour)
        return {...colourToStyle(colour), ...fullWidthStyle };
      else
        return fullWidthStyle;
    }

    return fullWidthStyle;
  };

  const formHasChanged = () => tagName !== initialTagName ||
    tagColourId !== initialTagColourId ||
    (() => {
      if (!tagTasks || !initialTagTasks || tagTasks.length !== initialTagTasks.length)
        return false; // something is wrong
      else
      {
        for (let i = 0; i < tagTasks.length; i++) {
          if (tagTasks[i].id !== initialTagTasks[i].id)
            return true;
        }
        return false;
      }
    })();

  const tagNameAlreadyExists = () => tagName !== initialTagName && tagNameExists(tagName);
  const tagNameIsValid = () => tagName && !tagNameAlreadyExists();

  const resetForm = () => {
    setTagName(initialTagName);
    setTagColourId(initialTagColourId);
    setTagTasks(initialTagTasks);
  };

  const saveTag = () => {

    const isNew = isNewTag();

    const tagToSave = {
      id: isNew ? undefined : tagId,
      name: tagName,
      colourId: tagColourId,
      taskIds: isNew ? undefined : tagTasks?.map(x => x.id)
    };

    if (isNew) { // add new
      const newTagId = addTag(tagToSave);
    
      setTagId(newTagId);
      history.push(`/tag/${newTagId}`); // update URL
    } else { // update
      updateTag(tagToSave);
    }

    setStatusMessage(`Tag has been ${(isNew ? 'add' : 'updat')}ed.`);
  };

  const canDelete = () => !tagTasks || tagTasks.length === 0;

  const deleteTagLocal = () => {
    if (canDelete()) {
      deleteTag(tagId);
      history.push({
        pathname: "/tags",
        state: {
          tagName: tagName,
          operation: 'deleted'
        }
      });
      setStatusMessage(`Tag has been deleted.`);
    }
  };

  return <div className="narrow">

    <Snackbar open={!!statusMessage} autoHideDuration={5000} onClose={() => setStatusMessage('')}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
      <MuiAlert elevation={6} variant="filled" onClose={() => setStatusMessage('')} severity="success">
        {statusMessage}
      </MuiAlert>
    </Snackbar>

    <Toolbar style={{ float: 'right'}}>

      {!isNewTag() &&
        <ButtonWithConfirmation action={deleteTagLocal}
                                buttonText="Delete"
                                dialogTitle="Are you sure you want to delete this tag?"
                                dialogContent="The tag will be permanently deleted."
                                cancelText="Cancel"
                                confirmText="OK, delete this tag"
                                color="secondary"
                                disabled={!canDelete()}
                                buttonIcon={<DeleteIcon />}
        />
      }

      <ButtonWithConfirmation action={resetForm}
                              buttonText="Cancel"
                              dialogTitle="Are you sure you want to reset these fields?"
                              dialogContent="This will discard any changes you made in this form."
                              cancelText="Cancel"
                              confirmText="OK, reset the form"
                              color="secondary"
                              buttonIcon={<CancelIcon />}
                              float="right"
                              disabled={!formHasChanged()}
                              style={{ float: 'right', marginLeft: '5px' }}
      />

      <Button color="primary"
              variant="contained"
              disabled={!formHasChanged() || !tagNameIsValid() || tagColourId === ''}
              onClick={saveTag}
              startIcon={<SaveIcon/>}
              style={{ float: 'right', marginLeft: '5px' }}
      >
        Save
      </Button>
    </Toolbar>

    <h2>{isNewTag() ? "Add New Tag" : "Tag Details"}</h2>

    <table style={{ width: '600px' }}>
        <tbody>
          <tr>{/* NAME */}
            <td>
              Name:
            </td>
            <td>
              <TextField id="tagName"
                         fullWidth
                         value={tagName}
                         variant="outlined"
                         error={!tagNameIsValid()}
                         placeholder="Enter new tag name"
                         helperText={
                           (!tagName && "Tag name is required.")
                             || (tagNameAlreadyExists() && "Tag name already exists.")
                         }
                         onChange={event => setTagName(event.target.value)} />
            </td>
          </tr>
          <tr>
            <td>
              Colour:
            </td>
            <td>
              <Select
                style={makeColourDropDownStyle(tagColourId)}
                variant="outlined"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={tagColourId}
                onChange={event => setTagColourId(event.target.value)}
                error={tagColourId === ''}
              >
                {
                  tagColours.map((colour, id) => (
                    <MenuItem key={id} value={id} style={colourToStyle(colour)}>
                      {colourToStr(colour)}
                    </MenuItem>
                  ))
                }
              </Select>
            </td>
          </tr>
        </tbody>
      </table>

    {
      !isNewTag() && <>
        <h2>Tasks with this Tag</h2>

        {
          tagTasks?.length > 0
            ? <>
                <div>
                  <em>Tip: you can only delete a tag if it has no tasks associated with it.</em>
                </div>
                <List>
                  <Container lockAxis="y"
                              getChildPayload={i => tagTasks[i]}
                              groupName="taskList"
                              dragHandleSelector=".column-drag-handle"
                              onDrop={e => {
                                const dragResult = applyDrag(e);
                                if (dragResult)
                                  setTagTasks(dragResult);
                              }}
                              dragClass="taskDrag"
                              dropPlaceholder={{                      
                                animationDuration: 150,
                                showOnTop: true,
                                className: 'taskDropPreview' 
                              }}
                  >
                    {tagTasks?.map(task => 
                        <Task key={task.id}
                              task={task}
                              toggleTaskComplete={() => toggleTaskComplete(task.date, task.id)}
                              showDescription={true}
                              showDate={true} />
                    )}
                  </Container>
                </List>
              </>
            : <em>No tags are currently using this task.</em>
        }
      </>
    }
  </div>
};

export default Tag;